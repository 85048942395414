import { auth } from "config/config"

export const PUBLIC_ROLES = ["Everyone"]
export const NON_SUBSCRIBER_ROLES = ["Handbook.NonSubscriber"]

/**
 * Takes a list of properties that are checked against user roles.
 * Users with the role `"Handbook.Admin"` are always authorized implicitly.
 * @example
 * ```js
 * // Given that user has the role `"Handbook.Subscriber"`
 * isAuthorized(user.roles, ["Handbook.Subscriber"]) // returns `true`
 * // Given that user has the role `"Handbook.Admin"`
 * isAuthorized(user.roles, []) // returns `true`
 * isAuthorized(user.roles) // returns `false`
 * isAuthorized(user.roles, ["Everyone"]) // returns `true`
 * ```
 *
 * @param userRoles
 * @param acceptedRoles
 * @returns boolean
 */
export function isAuthorized(userRoles?: string[], acceptedRoles?: string[]) {
    // 1. No accepted roles, public info in article is not loaded
    if (!acceptedRoles || !Array.isArray(acceptedRoles)) {
        return false
    }

    // 2. Indicates public data, anyone is authorized
    // Empty array or include one of PUBLIC_ROLES["Everyone"]
    if (
        !acceptedRoles?.length ||
        PUBLIC_ROLES.some(role => acceptedRoles.includes(role))
    ) {
        return true
    }

    // 3. User has no roles, not authorized for anything (except public)
    if (!userRoles) {
        return false
    }

    // 4. User is admin, they have access to all content
    if (isAdmin(userRoles)) {
        return true
    }

    // 5. User is admin, they have access to all content
    if (isEditor(userRoles)) {
        return true
    }

    // 6. User is not subscriber, not authorized
    if (!isSubscriber(userRoles)) {
        return false
    }

    // 6. Let's see if user has any of the accepted roles
    return userRoles.some(role => {
        return acceptedRoles.includes(role)
    })
}

/**
 * Check if the article is public
 * @param acceptedRoles Roles that are accepted of the article
 * @returns boolean indicating if the article is public
 */
export function isOpenAccess(acceptedRoles?: string[]) {
    if (!acceptedRoles || !Array.isArray(acceptedRoles)) {
        return false
    }

    return acceptedRoles.some(role => PUBLIC_ROLES.includes(role))
}

/**
 * Check if the user has admin role
 * @param roles list of roles from the user
 * @returns boolean indicating if the user has admin role
 */
export function isAdmin(roles: string[]) {
    if (!roles || !Array.isArray(roles)) {
        return false
    }

    return roles.some(role => auth.roles.admin.includes(role))
}

/**
 * Check if the user has CMS access
 * @param roles list of roles from the user
 * @returns boolean indicating if the user has CMS access
 */
export function isEditor(roles?: string[]) {
    if (!roles || !Array.isArray(roles)) {
        return false
    }

    // TODO: obviously wrong ..... ?
    const acceptedRoles = [...auth.roles.admin, ...auth.roles.editor]
    return roles.some(role => acceptedRoles.includes(role))
}

/**
 * Check if the user has subscriber role
 * @param roles list of roles from the user
 * @returns boolean indicating if the user has subscriber role
 */
export function isSubscriber(roles: string[] | undefined) {
    if (!roles || !Array.isArray(roles)) {
        return false
    }

    return roles.some(role => auth.roles.subscriber.includes(role))
}

// TODO: I want to rethink all of the roles, organisation and the logic that control access
// TODO: ...grouped roles differ in what access they should grant, the only group that makes sense for now is editor
